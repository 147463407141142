import React, {useEffect, useState, useRef} from "react";
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';

import videoImage from '../../assets/screen-preview.svg'

const videoSrc = {
    type: 'video',
    autoplay: false,
    muted: true,
    poster: videoImage,
    sources: [
      {
        src: 'https://public-assets-ts.s3.eu-north-1.amazonaws.com/TS+Intro+Video.mp4',
        type: 'video/mp4',
        size: 720,
      },
    ],
};


const VideoPlayer = () => {
    return(
        <div className="rounded-2xl overflow-hidden w-[90%]">
            <Plyr source={videoSrc} />
        </div>
    )
}

export default VideoPlayer;