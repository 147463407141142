import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';

import { ThemeProvider, Container} from "@mui/material"
import { ThemeCustom } from "../../styles/Theme";

import Logo from '../../assets/Logo.svg'
import ButtonMain from "../button/Button_main";
import closeIcon from '../../assets/close.svg'

const Navbar = ({requestDemo, closeFun}) => {

    const navigate = useNavigate();

    const getContact = () => {
        console.log('getContact')
        // const newWindow = window.open('https://forms.gle/4ZpuC6cJ3sATjTkaA', '_blank', 'noopener,noreferrer');
        // if (newWindow) newWindow.opener = null;
        navigate('/request-a-demo')
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className="py-5 fixed top-0 w-full bg-transparent backdrop-blur-md z-50 bg-white bg-opacity-40">
                <Container maxWidth="lg" className="flex justify-between items-center ">
                    <img src={Logo} alt="TalentScout logo" className="w-full max-w-[15rem] cursor-pointer" onClick={()=>navigate('/')}/>
                    {
                        requestDemo ? (
                            <ButtonMain 
                                variant='secondary' 
                                type='button' 
                                disabled={false} 
                                name='Request a Demo'
                                class='capitalize hidden sm:block'
                                onClick={getContact}
                            />
                        ):(
                            <div className="bg-cultured rounded-full w-12 h-12 flex justify-center items-center cursor-pointer duration-300 hover:bg-culturedDark text-lg my-[0.1rem]" onClick={closeFun}>
                                <img src={closeIcon} alt="close" className="w-6 h-6" />
                            </div>
                        )
                    }
                </Container>
            </div>
        </ThemeProvider>
    )
}

export default Navbar;