import { createTheme } from '@mui/material/styles';

export const ThemeMain = createTheme({
    palette: {
      primary: {
        main: '#232323',
        light: '#4f4f4f',
        dark: '#181818',
        lighter:'#ededed',
      },
      secondary: {
        main: '#0070FF',
        light: '#338cff',
        dark: '#004eb2',
      },
      gray: {
        main: '#878787',
        light: '#9f9f9f',
        dark: '#5e5e5e',
      },
      cultured: {
        main: '#ebebeb',
        light: '#F5F6FA',
        dark: '#abacaf',
        lighter:'#f7f7fb'
      },
      green: {
        main: '#00AB49',
        light: '#33bb6d',
        dark: '#007733',
      },
      red: {
        main: '#FF4D4F',
        light: '#ff7072',
        lighter: '#ffd9d9',
        dark: '#b23537',
      },
      yellow: {
        main: '#ffb207',
        light: '#ffc138',
        dark: '#b27c04',
      },
      white: {
        main: '#FFF',
      },
    },
    typography: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 16
    },
});  

export const ThemeCustom = createTheme({

  typography:{
      text12:{
        color: ThemeMain.palette.primary.main,
        fontSize:'0.75rem',
        fontWeight:'500'
      },
      text14:{
        color: ThemeMain.palette.primary.main,
        fontSize:'0.875rem',
        fontWeight:'500'
      },
      textBase:{
        color: ThemeMain.palette.primary.main,
        fontSize:'1rem',
        fontWeight:'500'
      },
      text20:{
        color: ThemeMain.palette.primary.main,
        fontSize:'1.25rem',
        fontWeight:'500'
      },
      textheader1:{
        color: ThemeMain.palette.primary.main,
        fontSize:'6rem',
        fontWeight:'700',
        lineHeight: 1.2
      },
      textheader2:{
        color: ThemeMain.palette.primary.main,
        fontSize:'3rem',
        fontWeight:'700',
        lineHeight: 1.2
      },
      textSubheader1:{
        color: ThemeMain.palette.primary.main,
        fontSize:'1.25rem',
        fontWeight:'600'
      }
  },    
  components:{
    MuiButton:{
      variants:[
        {
          props:{variant:'primary'},
          style:{
            color: ThemeMain.palette.white.main,
            backgroundColor: ThemeMain.palette.primary.main,
            fontFamily: ['Inter', 'sans-serif'].join(','),
            fontSize: '1rem',
            minWidth:'7.5rem',
            padding: '0.75rem 1.25rem',
            borderRadius:'0.75rem',
            '&:hover':{
              backgroundColor:ThemeMain.palette.primary.dark,
            },
            '&.Mui-disabled':{
              backgroundColor:ThemeMain.palette.primary.light
            }
          }
        },
        {
          props:{variant:'primaryOutline'},
          style:{
            color: ThemeMain.palette.primary.main,
            backgroundColor: ThemeMain.palette.white.main,
            border:`1px solid ${ThemeMain.palette.primary.main}`,
            fontFamily: ['Inter', 'sans-serif'].join(','),
            fontSize: '1rem',
            minWidth:'7.5rem',
            padding: '0.6875rem 1.25rem',
            borderRadius:'0.75rem',
            '&:hover':{
              backgroundColor:ThemeMain.palette.primary.lighter,
            },
            '&.Mui-disabled':{
              backgroundColor:ThemeMain.palette.primary.light
            }
          }
        },
        {
          props:{variant:'secondary'},
          style:{
            color: ThemeMain.palette.white.main,
            backgroundColor: ThemeMain.palette.secondary.main,
            fontFamily: ['Inter', 'sans-serif'].join(','),
            fontSize: '1rem',
            minWidth:'7.5rem',
            padding: '0.75rem 1.25rem',
            borderRadius:'0.75rem',
            '&:hover':{
              backgroundColor:ThemeMain.palette.secondary.dark,
            },
            '&.Mui-disabled':{
              backgroundColor:ThemeMain.palette.secondary.light
            }
          }
        },
      ]
    },
    MuiBackdrop :{
      styleOverrides:{
        root:{
          backgroundColor:'rgba(0, 0, 0, 0.3)'
        }
      }
    },
    MuiTooltip  :{
      styleOverrides:{
        tooltip:{
          color:ThemeMain.palette.white.main,
          backgroundColor:ThemeMain.palette.primary.main,
          fontSize:'0.825rem',
          fontWeight:'500',  
          borderRadius:'0.25rem'      
        },
      }  
    },
    MuiInputLabel:{
      styleOverrides:{
        root:{
          position:'relative',
          fontSize:'1rem',
          color: ThemeMain.palette.primary.main,
          fontFamily: ['Inter', 'sans-serif'].join(','),
          fontWeight: 500,
          transform:'inherit',
          marginBottom:'0.5rem',
          '&.Mui-focused':{
            color: ThemeMain.palette.primary.main
          }
        }
      }
    },
    MuiOutlinedInput:{
      styleOverrides:{
        root:{
          borderRadius:'0.75rem',
          width:'100%',
          backgroundColor: ThemeMain.palette.cultured.light,
          '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
            borderColor:  ThemeMain.palette.cultured.dark,
            borderWidth: '1px'
          },
          '& .MuiOutlinedInput-notchedOutline':{
            borderWidth: '0'
          },
        },
        input:{
          fontSize: '1rem',
          color: ThemeMain.palette.primary.main,
          fontFamily: ['Inter', 'sans-serif'].join(','),
          fontWeight: 500,
          padding: '0.75rem 1rem'
        }
      }
    },
    MuiFormControl:{
      styleOverrides:{
        root:{
          marginBottom:'1.5rem'
        }
      }  
    },
  }  
})      
