import React, { useState } from 'react';
import {Alert, IconButton, Collapse, ThemeProvider} from '@mui/material';

import {ThemeMain, ThemeCustom} from '../../styles/Theme';

import CloseIcon from '@mui/icons-material/Close';

const AlertError = (props) => {

    return(
        <ThemeProvider theme={ThemeMain}>
            <Collapse in={props.openFail}>
                <Alert
                    variant="filled" 
                    severity="error"
                    className='bg-red text-white rounded-xl mb-4 min-w-[30rem] max-w-[30rem] top-5 absolute z-[75] right-5'
                    action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            props.setOpenFail(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit"/>
                    </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {props.message}
                </Alert>
            </Collapse>
        </ThemeProvider>
    )

}

export default AlertError;