import React, {useEffect, useState} from "react";
import axios from 'axios';
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';

import { ThemeProvider, Container, FormControl, OutlinedInput, InputLabel, Typography} from "@mui/material"
import Grid from '@mui/material/Grid2'; 
import { ThemeCustom } from "../styles/Theme";

import ButtonMain from "../components/button/Button_main";
import Navbar from "../components/navbar/Navbar";
import AlertError from "../components/alert/Error_alert";

const BaseUrl = 'https://talentscoutstaging.xyz/v1/api/skillsource';

const Demo = () => {

    const {register, handleSubmit, formState: { errors },} = useForm();
    const navigate = useNavigate();
    const [submitSucess, setSubmitSucess] = useState(false)
    const [submitProcess, setSubmitProcess] = useState(false)
    const [openFail, setOpenFail] = useState(false)
    const [messageFail, setMessageFail] = useState('')

    //close function

    const closeFun = () => {
        navigate('/')
    }

    //data submit

    const onSubmit = (data) =>{

        console.log('submit', data);
        setSubmitProcess(true)

        axios
        .post(`${BaseUrl}/util/submitDemoForm`, data)
        .then((response) => {
            console.log('res', response);
            const res = response.data;
            if(res.code === 200){
                console.log('sucess res', res,data)
                setSubmitSucess(true)
            }
            else{
                console.error(res.message)
                failAlert(true, res.message)
                setSubmitSucess(false)
            }
            setSubmitProcess(false)
        })
        .catch((err) => {
            console.error('Internal Server Error', err)
            setSubmitSucess(false)
            failAlert(true, 'Internal Server Error')
            setSubmitProcess(false)
        })

    }

    //fail alert 

    useEffect(()=>{
        if(openFail){
            setTimeout(()=>{
                setOpenFail(false)
            },5000)
        }
    },[openFail])


    const failAlert = (status, message) => {
        setOpenFail(status)
        setMessageFail(message)
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <AlertError
                openFail={openFail}
                setOpenFail={setOpenFail}
                message={messageFail}
            />
            <div className="relative">
                <Navbar 
                    closeFun={closeFun}
                />
                <Container maxWidth="lg">
                    <div className="min-h-[calc(100vh-5.75rem)] mt-[5.75rem] flex items-center">
                        <Grid container spacing={2} className='mb-6'>
                            { !submitSucess ? (
                            <>
                                <Grid size={{xs:12, md: 6}} >
                                    <Typography component={'div'} className="cursor-default mb-5 sm:text-[4.5rem] text-[3rem] md:text-[6rem]" variant="textheader1">Request a <span className="text-secondary">Demo</span></Typography>
                                    <Typography component={'div'} className="cursor-default hidden md:block max-w-[80%] text-gray font-medium" variant="text16">
                                        Get a personalized walkthrough of TalentScout to see how it streamlines your hiring process, helping you find top talent faster than ever.
                                    </Typography>
                                    <Typography component={'div'} className="cursor-default md:hidden text-gray font-medium" variant="text16">
                                        Get a personalized walkthrough of TalentScout.
                                    </Typography>

                                </Grid>
                                <Grid size={{ xs:12, md: 6}}>
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <FormControl fullWidth variant="standard">
                                            <div className="flex justify-between">
                                                <InputLabel shrink htmlFor="userEmail">Name</InputLabel>
                                                {errors.name && <span className="text-red font-medium text-sm">{errors.name.message}</span>}
                                            </div>
                                            <OutlinedInput
                                                id="userName"
                                                placeholder="Enter your name"
                                                type='text'
                                                {...register("name", { required: 'Name is required'})}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth variant="standard">
                                            <div className="flex justify-between">
                                                <InputLabel shrink htmlFor="userEmail">Email</InputLabel>
                                                {errors.email && <span className="text-red font-medium text-sm">{errors.email.message}</span>}
                                            </div>
                                            <OutlinedInput
                                                id="userEmail"
                                                placeholder="Enter your email"
                                                type='text'
                                                {...register("email", { required: 'Email is required', pattern: {
                                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                    message: 'Invalid email address'} 
                                                })}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth variant="standard">
                                            <div className="flex justify-between">
                                                <InputLabel shrink htmlFor="userMobile">Phone Number</InputLabel>
                                                {errors.contactNo && <span className="text-red font-medium text-sm">{errors.contactNo.message}</span>}
                                            </div>
                                            <OutlinedInput
                                                id="userMobile"
                                                placeholder="Enter your phone number"
                                                type='text'
                                                {...register("contactNo", { required: 'Phone number is required', pattern: {
                                                    value: /^(\+?\d{1,4}[\s\-]?)?(\(?\d{1,3}\)?[\s\-]?)?(\d{3,4}[\s\-]?\d{3,4}[\s\-]?\d{3,4})$/,
                                                    message: 'Invalid phone number'} 
                                                })}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth variant="standard">
                                            <div className="flex justify-between">
                                                <InputLabel shrink htmlFor="userCompnay">Company<span className="text-xs text-gray ml-2">(optional)</span></InputLabel>
                                            </div>
                                            <OutlinedInput
                                                id="userCompnay"
                                                placeholder="Enter your compnay name"
                                                type='text'
                                                {...register("companyName")}
                                            />
                                        </FormControl>
                                        <ButtonMain 
                                            variant='secondary' 
                                            type='submit' 
                                            disabled={false} 
                                            name={ submitProcess ? 'Sending...' : 'Request Now'}
                                            class='w-full capitalize mt-5'
                                        />
                                    </form>
                                </Grid>  
                            </>
                            ):(
                                <Grid size={{xs:12}} className='flex flex-col items-center justify-center'>
                                    <Typography component={'div'} className="cursor-default mb-5 sm:text-[4.5rem] text-[3rem] md:text-[6rem] text-center" variant="textheader1">We'll Be in Touch <span className="text-secondary">Soon!</span></Typography>
                                    <Typography component={'div'} className="cursor-default md:max-w-[80%] text-gray font-medium text-center" variant="text16">
                                        We're excited to show you how TalentScout can transform your hiring process! Our team will be in touch shortly to schedule your demo.
                                    </Typography>
                                    <Typography component={'div'} className="cursor-default md:max-w-[80%] text-gray font-medium text-center mt-5" variant="text16">
                                        Have questions? Just email us at <a href="mailTo:talentscout.co@gmail.com" className="text-primary underline">talentscout.co@gmail.com</a>.
                                    </Typography>
                                    <Typography component={'div'} className="cursor-default md:max-w-[80%] text-gray font-medium text-center mt-5" variant="text16">
                                        Looking forward to connecting soon!
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </Container>
            </div>
        </ThemeProvider>        
    )

}

export default Demo;